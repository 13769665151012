html { 
}

body { 
    margin-top: 0 !important;
}

h1, h2, h3, h4, h5, h6 { 
    font-family: 'Open Sans';
}

a {
    /*color: #0083e8;*/
}

b, strong { 
}
 
.clr {
  clear: both;
  width: 100%;
}

.announcement {
    color: #ffffff;
    position: static !important;
}
.announcement__exit path {
    fill: #ffffff;
}
.announcement {
    /*background: #e36a5b;*/
    background: #edece8;
}

.header-holder {
    position: static !important; 
}

.header { 
    min-height: 100px;
}

.header .logo a { 
    min-height: 100px;
}

.header .logo img {
    max-height: 80px !important;
}
header.header-holder {
  padding: 14px 0 !important;
}
.sdc-wide-banner {
    width: 100%;
    /*padding: 32px;*/
    /*border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;*/
}

#shopify-section-1620411854e404eaf1 {
    /*background: #fff;*/
    padding-bottom: 80px !important;
    margin-bottom: 0;
}

#shopify-section-1620411854e404eaf1 { 
  padding-bottom: 0;
}
h5.footer-item__title {
   font-family: 'Open Sans', sans-serif;
   font-size: 0.8em;
}

.hp-heading1 p { 
    font-size: 1em !important;
    line-height: 1.4 !important; 
}

.hp-statistics p { 
    font-size: 1em !important;
    line-height: 1.4 !important;
    letter-spacing: 1px;
    font-family: 'Gotham Light', sans-serif;
    max-width: 450px;
    margin-bottom: 50px;
}

form.contact-form input  {
    border: 2px solid #0a0a0a;
    /*background: #edece8;*/
}
form.contact-form select {
    border: 2px solid #0a0a0a;
    -moz-appearance: button;
    -webkit-appearance: button;
    appearance: button;
}
form.contact-form button {
    background: #0a0a0a;
}
form.contact-form button svg {
    fill: #fff;
}

#shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0 .rich-text__content p {
    font-size: 4.1em !important;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
    font-weight: bold;
    line-height: 1.2em !important;
    margin-bottom: 70px; 
}

#shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0 span.section-header__title.h4 {
    font-family: 'Open Sans', sans-serif;
}

.sdc-wide-banner-desktop {
    display: block;
}
.sdc-wide-banner-mobile {
    display: none;
}


/***** Seller Drive Flair starts ******/
.sdf-top-strip {
    border-bottom: 1px solid #0a0a0a;
    background: #59c1a8 !important;
}
.sdf-header {
    background: #f7e46e;
}
.sdf-section-2 {
   width: 100%;
   background: #0a0a0a;
   padding-top: 80px;
   padding-bottom: 100px;
}
.sdf-section-2-inner {
   max-width: 1170px;
   margin: 0 auto;
   
}
.sdf-section-2-box {
    width: 38%;
    margin-right: 2%;
    background: #59c1a8;
    float: left;
}
.sdf-section-2-img {
    width: 50%;
    float: left;
    margin-right: 2%;
}
.sdf-section-2-img img {
    width: 100%;
    float: left;
}
.sdf-section-2-text {
    /*width: 58%; 
    float: right;*/
    max-width: 900px;
    width: 48%;
    float: right;
    margin: 0 auto;
    text-align: center;
    padding-left: 8%;
}
.sdf-section-2-text h2 {
    font-size: 50px;
    font-weight: bolder;
    margin-bottom: 30px;
    line-height: 60px;
    /*font-family: 'Gotham Black', sans-serif;*/
    text-align: left;
    color: #fff;
    margin-top: 100px;
}
/*.sdf-section-2-text h3 {
    font-size: 45px;
    font-weight: bolder;
    margin-bottom: 30px; 
    text-align: left;
    color: #fff;
}*/
.sdf-section-2-text p {
    font-size: 18px; 
    line-height: 28px;
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    color: #fff;
    
}
.sdf-section-3 {
   width: 100%;
   background: #fff;
   padding-top: 120px;
   padding-bottom: 120px;  
   
}
.sdf-section-3-inner {
   max-width: 1170px;
   margin: 0 auto;
   
}
.sdf-section-3-text {
    width: 23%;
    margin-right: 0%;
    float: left;
}
.sdf-section-3-text h3 {
    width: 100%; 
    float: left;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 15px;
}
.sdf-section-3-text h5 {
    width: 100%; 
    float: left;
    font-size: 15px; 
    line-height: 21px;
}
.sdf-section-3-boxes {
    width: 23%;
    margin-left: 2%;
    background: #59c1a8;
    float: right;
    padding: 3%;
    text-align: center;
    /*border: 1px solid #0a0a0a;*/
}
.sdf-section-3-boxes h3 {
    width: 100%; 
    float: left;
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.sdf-section-3-boxes img {
    max-height: 60px;
    margin-bottom: 20px;    
}

.sdf-button-section {
    background: url(https://cdn.shopify.com/s/files/1/0063/8792/5105/files/newletter-bg_17c79770-6b4c-4d10-9c9c-30a4dac5a78d.png?v=1667822566) repeat top;
    background-size: 22px !important; 
    padding-top: 75px;
    padding-bottom: 75px;
}
.sdf-button-section-inner {
    max-width: 1170px;   
    margin: 0 auto;
}
a.sdf-button-india {
    width: 45%;
    float: left;
    display: block;
    background: #f7e46e;
    padding: 2% 5%;
    margin-right: 5%;
    color: #0a0a0a;
    display: block;
    cursor: pointer;
    box-shadow: 10px 10px;
    border: 1px solid #0a0a0a;
}
.sdf-button-india img {
    /*max-width: 80px;*/
    min-width: 60px;
    float: right;
    width: 25%;
}
.sdf-button-india span {
    width: 75%;
    float: left;
    font-size: 25px;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;
    color: #0a0a0a;
    font-weight: bold;
    /*line-height: 84px;*/
    padding-top: 36px;
}
a.sdf-button-usa {
    width: 45%;
    float: right;
    display: block;
    background: #59c1a8;
    padding: 2% 5%; 
    color: #0a0a0a;
    display: block;
    cursor: pointer;
    box-shadow: 10px 10px;
    border: 1px solid #0a0a0a;
}
.sdf-button-usa img { 
    min-width: 60px;
    float: right;
    width: 25%;
}
.sdf-button-usa span {
    width: 75%;
    float: left;
    font-size: 25px;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;
    color: #0a0a0a;
    font-weight: bold;
    /*line-height: 84px;*/
    padding-top: 36px;
}
.sdf-tech-logos-outer {
    width: 100%;
    overflow: hidden;
}
.sdf-tech-logos {
    position: inherit;
    padding-bottom: 0;
    margin-bottom: 20px;
}
/***** Seller Drive Flair ends ******/


@media (max-width: 700px) {
    #shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0 {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    #shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0 .rich-text__content p {
        font-size: 30px !important;
        margin-bottom: 40px !important;
    }
    .sdc-wide-banner-desktop {
        display: none;
    }
    .sdc-wide-banner-mobile {
        display: block;
    }
    
    /***** Seller Drive Flair starts ******/
    .sdf-section-2-inner,
    .sdf-section-3-inner {
        padding-left: 15px;
        padding-right: 15px;
    }
    .sdf-section-3-text,
    .sdf-section-3-boxes { 
        width: 47%;
        float: left;
        display: block;
        margin-bottom: 20px;
        height: 260px;
        margin-left: 0;
        margin-right: 3%;
    }
    .sdf-section-3-boxes { 
        padding-top: 10%;
    }
    a.sdf-button-india,
    a.sdf-button-usa {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 50px;
        float: left;
        margin-left: 4%;
    }
    .sdf-section-2-img {
        width: 100%;
        padding-left: var(--grid-padding);
        padding-right: var(--grid-padding);
    }
    .sdf-section-2-text {
        width: 100%;
        padding-left: var(--grid-padding);
        padding-right: var(--grid-padding);
        margin-top: 40px;
    }
    .sdf-section-2-text h2 { 
        margin-top: 20px;
    }
    /***** Seller Drive Flair ends ******/
}

/*** Login button container styles ***/
/* CSS Styles */
.login-div .login-button {
    padding: 8px 30px 8px 30px;
    background-color: #edece8; /* or any color you prefer */
    color: black; /* or any color you prefer */
    text-decoration: none;
    border-radius: 0px;
    font-weight: bold;
    opacity: 1;
  }

.login-div .login-button:hover{
    opacity: 0.8;
  }